import firebase, {db, auth, fc} from './connection'
import store from '../store.js'
import router from '../router.js'

export default {
    getAll() {
        let ref = db.collection('users');

        return ref.get()
                    .then(query => {
                        return query;
                    }).catch(error => {
                        return error;
                    });
    },
    
    update(uid, model) {
        let ref = db.collection('users').doc(uid);

        return ref.update(model)
                    .then( () => {
                        let user = store.getters.user;
                        store.dispatch('setUser', { ...model, ...user });
                        return true;
                    }).catch(error => {
                        return error;
                    });
    },

    updatePending(profile, model) {
        let ref = db.collection('users').doc(profile.id);

        return ref.update(model)
                    .then( () => {
                        if (model.pending === false) {
                            let sendWelcome = fc.httpsCallable('sendWelcome');
                            return sendWelcome(profile)
                                        .then(response => {
                                            // return { data: response.data };
                                            return response.data;
                                        })
                                        .catch(error => {
                                            return error;
                                        });
                        } else {
                            return true
                        }
                    }).catch(error => {
                        return error;
                    });
    },

    getTop20(uid) {
        let getTop20 = fc.httpsCallable('getTop20');

        return getTop20(uid)
                    .then(response => {
                        return { data: response.data };
                    })
                    .catch(error => {
                        return error;
                    });
    }
}
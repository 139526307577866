<template>
	<div class="flex items-center justify-center bg-gray-50 pt-4 sm:pt-12 pb-12 px-4 sm:px-6 lg:px-8">
		<div class="max-w-xl w-full">
			<div>
				<h2 class="mt-6 text-center text-3xl font-extrabold text-accent">
					Mis puntos
				</h2>
				<p class="mt-3 text-center text-sm text-gray-600">
					Revisa tu puntuación y la de los demás navegantes en el <span class="font-semibold">Top 20</span>  para que lleves un seguimiento y establezcas tus metas para ganar.
				</p>
			</div>
			

			<div class="flex justify-center mt-8">
				<div class="mt-6 py-6 px-20 bg-white rounded-lg shadow space-y-3">
					<div class="flex justify-center">
						<AdjustmentsIcon class="w-6 h-6 text-accent"/>
					</div>
					<div class="space-y-2">
						<div class="text-xl sm:text-3xl xl:text-4xl font-bold text-center text-green-500">
							{{ withCommas(state.user.casePoints + state.user.gamePoints)}} pts.
						</div>
						<div class="text-center text-xs text-gray-600 font-medium">
							PUNTOS ACUMULADOS
						</div>
					</div>
				</div>
			</div>


			<div class="mt-12" v-if="state.user">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Detalle de puntos
				</h3>

				<dl class="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2">
					<div class="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
						<dt>
							<div class="absolute bg-accent rounded-md p-3">
								<DocumentTextIcon class="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p class="ml-16 text-xs font-medium text-gray-500 truncate">DEL CASO CLÍNICO</p>
						</dt>
						<dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
							<p class="text-2xl font-semibold text-gray-600">
								{{ withCommas(state.user.casePoints) }} pts.
							</p>
						</dd>
					</div>

					<div class="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
						<dt>
							<div class="absolute bg-accent rounded-md p-3">
								<LightningBoltIcon class="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p class="ml-16 text-xs font-medium text-gray-500 truncate">DEL JUEGO</p>
						</dt>
						<dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
							<p class="text-2xl font-semibold text-gray-600">
								{{ withCommas(state.user.gamePoints) }} pts.
							</p>
						</dd>
					</div>
				</dl>
			</div>

			<!-- <div class="mt-12">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Posición actual
				</h3>

				<div class="mt-4 bg-white shadow rounded-lg py-3 px-5">
					<div class="font-medium space-x-1">
						<span class="text-xl sm:text-3xl text-accent">28</span>
						<span class="text-base sm:text-lg text-gray-600">de 256 jugadores.</span>
					</div>
				</div>
			</div> -->

			<!-- <div class="mt-6" v-if="!state.showTop">
				<button class="mt-6 group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" @click="state.showTop=true">
					<span class="absolute left-0 inset-y-0 flex items-center pl-3">
						<UsersIcon class="h-5 w-5 text-white group-hover:text-white opacity-50" aria-hidden="true" />
					</span>
					Ver Top 20
				</button>
			</div> -->


			<!-- // LISTA -->
			<template v-if="state.loaded">
				<h3 class="text-lg leading-6 font-medium text-gray-900 mt-12">
					Top 20
				</h3>

				<img :src="require('@/assets/points/lighthouse.jpg')" class="rounded-t-lg mt-4 shadow" alt="">
				<div class="flex flex-col">
					<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden ">

							<table class="min-w-full divide-y divide-gray-200" v-if="Object.entries(state.top20).length > 0">
								<thead class="bg-black">
								<tr>
									<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
									Posición
									</th>
									<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
									Jugador
									</th>
									<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
									Puntos
									</th>
								</tr>
								</thead>
								<tbody>
									<tr v-for="(person, personIdx) in state.top20" :key="person.email" :class="personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
										<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
											{{ personIdx+1 }}
										</td>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{{ person.name }} {{ person.paterno }}
											<ShieldCheckIcon class="w-5 h-5 text-green-500 ml-2 mb-1 inline-block" v-if="personIdx==0"/>
										</td>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{{ person.totalWithCommas }} 
										</td>
									</tr>
								</tbody>
							</table>

							<div class="bg-white p-12 text-center font-semibold text-accent text-xs" v-else>
								AÚN NO HAY JUGADORES CON REGISTRO DE PUNTOS.
							</div>
						
						</div>
					</div>
					</div>
				</div>
			</template>

			<div class="flex justify-center py-20" v-if="!state.loaded">
				<Loader />
			</div>

		</div>
		
	</div>
</template>

<script>
import { ArrowNarrowDownIcon, AdjustmentsIcon, DocumentTextIcon, LightningBoltIcon, UsersIcon, GiftIcon, ShieldCheckIcon } from '@heroicons/vue/solid'
import { computed, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import Users from '@/firebase/users'
import Loader from '@/components/core/Loader'
import Misc from '@/services/misc'

export default {
	components: {
		ArrowNarrowDownIcon,
		AdjustmentsIcon, 
		LightningBoltIcon,
		DocumentTextIcon,
		UsersIcon,
		GiftIcon,
		ShieldCheckIcon,
		Loader
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.getters.user);

		const state = reactive({
			user: user,
			loaded: false
		});

		onMounted(() => {
			init();
		});

		function init() {
			Users.getTop20(state.user.uid)
				.then(response => {
					if (response.data) {
						state.top20 = response.data;
						state.top20.forEach(dt => {
							dt.totalWithCommas = withCommas(dt.totalPoints)
						});
						state.loaded = true;
					} else {
						store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión.'});
					}
				});
		}

		function withCommas(num) {
			return Misc.numberWithCommas(num)
		}

		return {
			state,
			withCommas
		}
	},
}
</script>
